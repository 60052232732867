<!--  -->
<template>
<div class=''><el-descriptions title="垂直带边框列表" direction="vertical" :column="3" border>
    <el-descriptions-item label="站点名称" label-class-name="my-label">{{dataObj.site_name}}</el-descriptions-item>
  <el-descriptions-item label="用户名" label-class-name="my-label">{{dataObj.order_contact_name}}</el-descriptions-item>
  <el-descriptions-item label="手机号" label-class-name="my-label">{{dataObj.order_contact_phone}}</el-descriptions-item>
  <el-descriptions-item label="订单号" label-class-name="my-label">{{dataObj.order_number}}</el-descriptions-item>
  <el-descriptions-item label="订单状态" label-class-name="my-label">
    <el-tag v-if="dataObj.order_status==-2">订单关闭</el-tag>
<el-tag type="success" v-if="dataObj.order_status==-1">已退款</el-tag>
<el-tag type="info" v-if="dataObj.order_status==0">待支付</el-tag>
<el-tag type="warning" v-if="dataObj.order_status==1">待消费</el-tag>
<el-tag type="danger" v-if="dataObj.order_status==2">消费中</el-tag> 
<el-tag  v-if="dataObj.order_status==3">已完成</el-tag>
  </el-descriptions-item>

  <el-descriptions-item label="订单金额" label-class-name="my-label">{{dataObj.order_amount}}</el-descriptions-item>
  <el-descriptions-item label="支付金额" label-class-name="my-label">{{dataObj.payment_amount}}</el-descriptions-item>
  <el-descriptions-item label="订单备注" label-class-name="my-label">{{dataObj.order_remarks}}</el-descriptions-item>
  <el-descriptions-item label="创建时间" label-class-name="my-label" :span="4">{{dataObj.create_time}}</el-descriptions-item>
  <el-descriptions-item label="订单子项" label-class-name="my-label" :span="4">
    <el-table
          ref="multipleTable"
          :data="dataObj.order_server_list"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="dataObj.order_server_list"
        >
        <el-table-column
            align="center"
            prop="service_name"
            label="站点服务名称"
          >
          </el-table-column>
        <el-table-column
            align="center"
            prop="price_image"
            label="商品图片"
          >
          <template scope="scope">
            <img style="width: 150px;height: 150px;" :src="scope.row.service_cover_image" />
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="price"
            label="价格"
          >
          </el-table-column>
     
          <el-table-column
            align="center"
            prop="sub_number"
            label="数量"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sub_start_date"
            label="开始时间"
          >
 
          </el-table-column>
          <el-table-column
            align="center"
            prop="sub_end_date"
            label="结束时间"
          >
 
          </el-table-column>
          <el-table-column
            align="center"
            prop="create_time"
            label="创建时间"
          >
          </el-table-column>
        </el-table>
</el-descriptions-item>
</el-descriptions></div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostOrderDetail} from "@/api/orderList/orderDetail"
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
    dataObj:null
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    /*---------------------------生命周期函数-----------------------------*/
    async PostOrderDetail(data){
        let res=await PostOrderDetail(data)
        if(res.data.status==1){
               this.dataObj=res.data.result;
         console.log(res);
        }else{
            this.$message("error",res.data.message)
        }
     
    }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {
    let id=this.$route.params.id;
this.PostOrderDetail({
    order_id:id
})
},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
 .my-label {
    background: red;
  }
</style>